import { request } from '../common/utils/request'
import errorMessage from '../common/utils/errorMessage'

export const SEARCH_PROJECT = 'SEARCH_PROJECT'
export const GET_PROJECT = 'GET_PROJECT'
export const ADD_PROJECT = 'ADD_PROJECT'
export const CHANGE_PROJECT_STATUS = 'CHANGE_PROJECT_STATUS'

export const search = url => ({
	type: SEARCH_PROJECT,
	payload: request.get('/projects', { url }).catch(() => errorMessage()),
})

export const getProjectById = id => ({
	type: GET_PROJECT,
	payload: request.get('/projects', { id }).catch(() => errorMessage()),
})

export const addProject = (url, status, comment) => ({
	type: ADD_PROJECT,
	payload: request.post('/projects', { url, status, comment }).catch(() => errorMessage()),
})

export const changeStatus = (id, status, comment) => ({
	type: CHANGE_PROJECT_STATUS,
	payload: request.put(`/projects/${id}`, { status, comment }).catch(() => errorMessage()),
})
