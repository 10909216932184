import { AUTH_DATA } from '../actions/authActions'

export default (state = { init: false }, action = {}) => {
	switch (action.type) {
		case AUTH_DATA:
			if (!action.error) return { init: true, ...action.payload.data }

			return state

		default:
			return state
	}
}
