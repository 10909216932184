import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Paper, Typography, Button } from '@material-ui/core'
import { ReduxFormPassword } from '../../common/form/ReduxFormField'
import { resetPassword } from '../../actions/authActions'

const validate = values => {
	const errors = {}

	if (!values.password) errors.password = 'Required'
	if (!values.password_confirmation) errors.password_confirmation = 'Required'

	if (values.password && values.password.length < 6) errors.password = 'Password is too short'

	if (values.password !== values.password_confirmation) {
		errors.password_confirmation = "Passwords don't match"
	}

	return errors
}

const ResetPassword = ({ handleSubmit, resetPassword, invalid }) => (
	<form className="reset-password" onSubmit={handleSubmit(resetPassword)}>
		<Paper className="container">
			<Typography variant="h5">Create new password</Typography>
			<Field component={ReduxFormPassword} name="password" label="New password" />
			<Field component={ReduxFormPassword} name="password_confirmation" label="Confirm password" />
			<Button type="submit" disabled={invalid}>
				Confirm
			</Button>
		</Paper>
	</form>
)

const mapDispatchToProps = { resetPassword }

export default connect(
	(
		_,
		{
			match: {
				params: { token },
			},
		}
	) => ({
		initialValues: { token },
	}),
	mapDispatchToProps
)(
	reduxForm({
		form: 'resetPassword',
		onSubmitSuccess: ({ payload }, _, props) =>
			payload.status && payload.status === 200 && props.history.push('/login'),
		validate,
	})(ResetPassword)
)
