import React, { Component } from 'react'
import { connect } from 'react-redux'

import { search, addProject, getProjectById, changeStatus } from '../../actions/projectActions'

import UpworkCheckerSearch from '../content/UpworkChecker/UpworkCheckerSearch'
import UpworkCheckerForm from '../content/UpworkChecker/UpworkCheckerForm'
import UpworkCheckerHistory from '../content/UpworkChecker/UpworkCheckerHistory'

class UpworkChacker extends Component {
	state = {
		data: null,
		searchQuery: '',
		commentValue: '',
		statusValue: '',
		loading: false,
		submiting: false,
	}

	_onSubmit = () => {
		const { data, searchQuery, statusValue, commentValue } = this.state

		this.setState({ commentValue: '', statusValue: '', submiting: true }, () => {
			if (data && data.project)
				return this.props
					.changeStatus(data.project.id, statusValue ? statusValue : data.project.status, commentValue)
					.then(res =>
						this.setState({ data: { ...this.state.data, project: res.payload.data }, submiting: false })
					)

			return this.props.addProject(searchQuery, statusValue ? statusValue : 1, commentValue).then(res => {
				this.setState({ data: { ...this.state.data, project: res.payload.data }, submiting: false })
				if (res.payload.data) {
					this.props.history.push(`/upwork-checker/${res.payload.data.id}`)
				} else {
					this.props.history.push('/upwork-checker')
				}
			})
		})
	}

	handleChange = e => this.setState({ [e.target.name]: e.target.value })

	handleSearch = () => {
		this.setState({ data: null, commentValue: '', statusValue: '', loading: true }, () =>
			this.props.search(this.state.searchQuery).then(res => {
				if (!res.payload) return
				this.setState({ data: res.payload.data, loading: false })
				if (res.payload.data.project) {
					this.props.history.push(`/upwork-checker/${res.payload.data.project.id}`)
				} else {
					this.props.history.push('/upwork-checker')
				}
			})
		)
	}

	componentDidMount() {
		const {
			match: {
				params: { id },
			},
			getProjectById,
		} = this.props
		if (!id) return

		this.setState({ data: null, commentValue: '', statusValue: '', loading: true }, () =>
			getProjectById(id).then(res => {
				if (!res.payload) return
				this.setState({ data: res.payload.data, loading: false })
			})
		)
	}

	render() {
		const { data, searchQuery, commentValue, statusValue, loading, submiting } = this.state
		return (
			<>
				<UpworkCheckerSearch
					data={data}
					searchValue={searchQuery}
					loading={loading}
					handleChange={this.handleChange}
					handleSearch={this.handleSearch}
				/>
				{data && (
					<UpworkCheckerForm
						data={data}
						searchValue={searchQuery}
						commentValue={commentValue}
						statusValue={statusValue}
						submiting={submiting}
						handleChange={this.handleChange}
						addProject={this._onSubmit}
					/>
				)}
				{data && data.project && <UpworkCheckerHistory project={data.project} />}
			</>
		)
	}
}

export default connect(
	({ account, settings, project }) => ({ account, settings, project }),
	{ search, addProject, getProjectById, changeStatus }
)(UpworkChacker)
