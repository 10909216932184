import { GET_HISTORY } from '../actions/historyActions'

export default (state = { data: [] }, action) => {
	switch (action.type) {
		case GET_HISTORY:
			return action.payload.data
		default:
			return state
	}
}
