import { LOG_IN } from '../actions/authActions'
import { CHANGE_SETTINGS } from '../actions/profileActions'

export default (state = {}, action = {}) => {
	switch (action.type) {
		case LOG_IN:
			let accountData = {}

			if (action.error || !action.payload.data) {
				accountData = { loggedIn: false }
			} else {
				accountData = {
					loggedIn: true,
					token: action.payload.data.token,
					user: action.payload.data.user,
				}
			}

			return accountData

		case CHANGE_SETTINGS:
			if (action.error || !action.payload.data) {
				return state
			}
			return {
				...state,
				user: action.payload.data,
			}

		default:
			return state
	}
}
