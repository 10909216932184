import { toast } from 'react-toastify'

export default (message = 'Something went wrong') =>
	toast.error(message, {
		position: 'bottom-right',
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
	})
