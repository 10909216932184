import React, { Component } from 'react'
import {
	FormControl,
	InputLabel,
	Input,
	FormControlLabel,
	Checkbox,
	InputAdornment,
	IconButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

export const ReduxFormField = ({ input, label, meta: { touched, error }, className, ...props }) => (
	<FormControl error={touched && !!error} className={className}>
		<InputLabel>{touched && error ? error : label}</InputLabel>
		<Input {...input} {...props} />
	</FormControl>
)

export class ReduxFormPassword extends Component {
	state = {
		isVisible: false,
	}

	toggleVisibility = () => this.setState(prevState => ({ isVisible: !prevState.isVisible }))

	render() {
		const {
			input,
			label,
			meta: { touched, error },
			className,
			...props
		} = this.props
		return (
			<FormControl error={touched && !!error} className={className}>
				<InputLabel>{touched && error ? error : label}</InputLabel>
				<Input
					type={this.state.isVisible ? 'text' : 'password'}
					endAdornment={
						<InputAdornment position="end">
							{!!input.value.length && (
								<IconButton aria-label="Toggle password visibility" onClick={this.toggleVisibility}>
									{this.state.isVisible ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							)}
						</InputAdornment>
					}
					{...input}
					{...props}
				/>
			</FormControl>
		)
	}
}

export const ReduxFormCheckbox = ({ input: { value, ...inputProps }, ...props }) => (
	<FormControlLabel control={<Checkbox checked={!!value} name="remember_me" {...inputProps} />} {...props} />
)
