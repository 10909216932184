import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import store from './reducers'
import registerServiceWorker from './registerServiceWorker'
import Root from './components/containers/Root'

import 'react-toastify/dist/ReactToastify.css'
import './static/scss/app.scss'

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Root />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)

registerServiceWorker()
