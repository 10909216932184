import React from 'react'
import { Link } from 'react-router-dom'
import { Field } from 'redux-form'
import {
	Button,
	Typography,
	Grid,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'
import { ReduxFormField, ReduxFormPassword } from '../../../common/form/ReduxFormField.jsx'
import { theme } from '../../../common/utils/theme.js'

const submitSettings = (changeUserSettings, initialValues, initialize) => values =>
	changeUserSettings(values, () =>
		initialize({
			...initialValues,
			first_name: values.first_name,
			last_name: values.last_name,
		})
	)

const ProfileSettingsForm = ({
	pristine,
	invalid,
	submitting,
	handleSubmit,
	changeUserSettings,
	initialize,
	initialValues,
}) => (
	<MuiThemeProvider theme={theme}>
		<form
			onSubmit={handleSubmit(submitSettings(changeUserSettings, initialValues, initialize))}
			className="profile-form"
		>
			<Typography variant="h5" className="title">
				User Settings
			</Typography>
			<ExpansionPanel expanded>
				<ExpansionPanelDetails>
					<Field component={ReduxFormField} label="First Name" name="first_name" className="text-field" />
					<Field component={ReduxFormField} name="last_name" label="Last name" className="text-field" />
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<ExpansionPanel>
				<ExpansionPanelSummary expandIcon={<ExpandMore />}>Change password</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Field
						component={ReduxFormPassword}
						name="old_password"
						label="Old password"
						className="text-field"
					/>
					<Field component={ReduxFormPassword} name="password" label="New password" className="text-field" />
					<Field
						component={ReduxFormPassword}
						name="password_confirmation"
						label="Confirm password"
						className="text-field"
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Grid container spacing={16} justify="space-between" className="buttons">
				<Grid item>
					<Button component={Link} to="/">
						Go back
					</Button>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						color="primary"
						disabled={pristine || invalid || submitting}
						type="submit"
					>
						Update Profile
					</Button>
				</Grid>
			</Grid>
		</form>
	</MuiThemeProvider>
)

export default ProfileSettingsForm
