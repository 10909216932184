import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { Field, reduxForm } from 'redux-form'
import { ReduxFormField } from '../../../common/form/ReduxFormField'

const validate = values => {
	const errors = {}

	if (!values.email) {
		errors.email = 'Required'
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address'
	}

	return errors
}

const ResetPasswordDialog = ({ open, onClose, handleSubmit, invalid }) => (
	<Dialog open={open} onClose={onClose}>
		<form onSubmit={handleSubmit}>
			<DialogTitle>Enter email to restore password</DialogTitle>
			<DialogContent>
				<Field component={ReduxFormField} autoFocus fullWidth name="email" />
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={onClose} disabled={invalid} type="submit">
					Send
				</Button>
			</DialogActions>
		</form>
	</Dialog>
)

export default reduxForm({
	form: 'resetPasswordRequest',
	validate,
})(ResetPasswordDialog)
