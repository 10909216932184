import { toast } from 'react-toastify'
import { SubmissionError } from 'redux-form'
import { request } from '../common/utils/request'
import errorMessage from '../common/utils/errorMessage.js'

export const CHANGE_SETTINGS = 'CHANGE_SETTINGS'

export const changeUserSettings = (newSettings, callback = () => {}) => ({
	type: CHANGE_SETTINGS,
	payload: request
		.put('/user', newSettings)
		.then(res => {
			callback(res)
			toast.success('Successfully saved settings', {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
			})
			return res
		})
		.catch(err => {
			errorMessage()
			throw new SubmissionError(err.response.data.errors)
		}),
})
