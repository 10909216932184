import React from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { changeUserSettings } from '../../actions/profileActions'
import ProfileSettingsForm from '../content/Profile/ProfileSettingsForm'

const ProfileSettings = props => <ProfileSettingsForm {...props} />

const mapStateToProps = ({
	account: {
		user: { first_name, last_name },
	},
}) => ({ initialValues: { first_name, last_name } })

const mapDispatchToProps = { changeUserSettings }

const validate = values => {
	const errors = {}
	if (values.password !== values.password_confirmation) {
		errors.password = "Passwords don't match"
		errors.password_confirmation = "Passwords don't match"
	}
	const passwordFields = ['old_password', 'password', 'password_confirmation']
	if (passwordFields.some(field => values[field])) {
		passwordFields.forEach(field => {
			if (!values[field]) {
				errors[field] = 'Required'
			}
		})
	}
	if (values.password && values.password.length < 6) {
		errors.password = 'password is too short'
	}
	return errors
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: 'profileSettings',
		validate,
	})(ProfileSettings)
)
