import { request } from '../common/utils/request'
import errorMessage from '../common/utils/errorMessage.js'

export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'
export const AUTH_DATA = 'AUTH_DATA'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'

export const login = (data = {}) => {
	return {
		type: LOG_IN,
		payload: request
			.post(`/auth/login`, data)
			.catch(err =>
				errorMessage(err.response.status === 422 ? 'Wrong login or password' : 'Something went wrong')
			),
	}
}

export const check = callback => {
	return request
		.get('/auth/check')
		.then(() => {
			callback()
		})
		.catch(() => errorMessage())
}

export const authData = () => {
	return {
		type: AUTH_DATA,
		payload: request.get('/auth/data').catch(() => errorMessage()),
	}
}

export const resetPasswordRequest = email => ({
	type: RESET_PASSWORD_REQUEST,
	payload: request.post('/auth/reset-password', { email }).catch(() => errorMessage()),
})

export const resetPassword = data => ({
	type: RESET_PASSWORD_REQUEST,
	payload: request.put('/auth/reset-password', data).catch(() => errorMessage()),
})

export const logout = () => {
	return { type: LOG_OUT }
}
