import { SEARCH_PROJECT, GET_PROJECT } from '../actions/projectActions'

export default (state = {}, action = {}) => {
	switch (action.type) {
		case SEARCH_PROJECT:
		case GET_PROJECT:
			return { ...action.payload.data }
		default:
			return state
	}
}
