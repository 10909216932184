import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getHistory } from '../../actions/historyActions'
import HistoryContent from '../content/History/History'

class History extends Component {
	componentDidMount() {
		this.props.getHistory()
	}

	render() {
		return <HistoryContent history={this.props.history.data} />
	}
}

const mapStateToProps = ({ history }) => ({ history })

const mapDispatchToProps = { getHistory }

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(History)
