import React, { Component } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'

import Header from '../content/share/Header'
import Subheader from '../content/share/Subheader'
import Content from '../content/share/Content'
import { theme } from '../../common/utils/theme'

class App extends Component {
	render() {
		const { location } = this.props
		return (
			<MuiThemeProvider theme={theme}>
				<Header />
				<Subheader location={location} />
				<Content />
			</MuiThemeProvider>
		)
	}
}

export default App
