import React from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute, GuestRoute } from '../common/helpers/routes'
import App from '../components/containers/App'
import Login from '../components/containers/Login'
import ResetPassword from '../components/containers/ResetPassword'

const RootRouter = ({ loggedIn }) => {
	return (
		<Switch>
			<GuestRoute path="/login" loggedIn={loggedIn} component={Login} />
			<GuestRoute path="/resetPassword/:token" loggedIn={loggedIn} component={ResetPassword} />
			<PrivateRoute strict path="/" loggedIn={loggedIn} component={App} />
		</Switch>
	)
}

export default RootRouter
