import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar'
import { logout } from '../../actions/authActions'
import setAuthToken from '../../common/utils/setAuthToken'
import RootRouter from '../../routers/RootRouter'

class Root extends Component {
	constructor(props) {
		super(props)

		this.state = {
			authCheck: !props.account.loggedIn,
			requestCondition: '',
			snackMessage: false,
		}
	}

	closeSnackBar = () => {
		this.setState({ snackMessage: false })
	}

	componentWillMount() {
		// add auth token to each request (if exists)
		setAuthToken(this.props.account.token)

		// Add a request interceptor
		axios.interceptors.request.use(
			config => {
				this.setState({ requestCondition: 'loading' })

				return config
			},
			error => {
				this.setState({ requestCondition: 'complete' })

				return Promise.reject(error)
			}
		)

		// handle axios behaviour on 401 response error
		axios.interceptors.response.use(
			response => {
				this.setState({ requestCondition: 'complete' })

				return response
			},
			error => {
				this.setState({ requestCondition: 'complete' })

				if (error.response.status === 401) {
					this.props.logout()
				} else {
					let snackMessage =
						typeof error.response.data === 'string' ? error.response.data : error.response.statusText

					this.setState({ snackMessage })
				}

				return Promise.reject(error)
			}
		)

		// if (!this.state.authCheck) {
		// 	check(() => {
		// 		this.setState({ authCheck: true })
		// 	})
		// }
	}

	render() {
		const {
			account: { loggedIn },
		} = this.props
		return (
			<>
				<Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					open={false}
					autoHideDuration={6000}
					message={<span id="message-id">Note archived</span>}
				/>
				<RootRouter loggedIn={loggedIn} />
				<ToastContainer />
			</>
		)
	}
}

export default withRouter(
	connect(
		({ account }) => {
			return { account }
		},
		{ logout }
	)(Root)
)
