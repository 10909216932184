import React from 'react'
import { Button, InputAdornment } from '@material-ui/core'
import Email from '@material-ui/icons/Email'
import { Field } from 'redux-form'
import { ReduxFormField, ReduxFormCheckbox, ReduxFormPassword } from '../../../common/form/ReduxFormField'

const LoginContent = ({ login, handleSubmit, handleForgotPassword }) => (
	<div className="login-page">
		<div className="login-wrap">
			<div className="login-bg" />
			<div className="login-form">
				<form onSubmit={handleSubmit(login)}>
					<h3 className="login-title">
						Log <span>in</span>
					</h3>
					<Field
						component={ReduxFormField}
						className="input-wrap"
						name="email"
						label="Email"
						endAdornment={
							<InputAdornment position="end">
								<Email className="email-icon" />
							</InputAdornment>
						}
					/>
					<Field component={ReduxFormPassword} className="input-wrap" name="password" label="Password" />
					<Button size="small" onClick={handleForgotPassword}>
						Forgot password?
					</Button>
					<Field component={ReduxFormCheckbox} label="Remember me" name="remember_me" />
					<Button type="submit" className="login-button" color="primary">
						Go to work
					</Button>
				</form>
			</div>
		</div>
	</div>
)

export default LoginContent
