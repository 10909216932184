import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import { Link } from 'react-router-dom'

const History = ({ history }) => {
	const colors = {
		'Need review': 'blue',
		Declined: 'red',
		default: 'green',
	}

	return (
		<Paper className="history-wrap">
			<Typography className="title" component="h3" variant="h6">
				History
			</Typography>
			{history.map((item, index) => {
				return (
					<Grid
						container
						spacing={16}
						key={index}
						className="history-item"
						component={Link}
						to={`/upwork-checker/${item.id}`}
					>
						<Grid item xs container spacing={16} direction="column">
							<Grid item container>
								<Grid item className="name-wrap">
									<span className="name">{item.url}</span>
								</Grid>
								<Grid item xs>
									<p className="created-at">{item.created_at}</p>
								</Grid>
							</Grid>
							<Grid item container>
								<Grid item>
									<p className="status-placeholder">Status:</p>
								</Grid>
								<Grid item>
									<span className={`status ${colors[item.status] || colors.default}`}>
										{item.status}
									</span>
								</Grid>
							</Grid>
							<Grid item>{item.comment}</Grid>
						</Grid>
					</Grid>
				)
			})}
		</Paper>
	)
}

export default History
