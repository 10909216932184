import React from 'react'
import { CircularProgress, Button, TextField, Paper, Grid } from '@material-ui/core'

const ProjectSearch = ({ data, searchValue, loading, handleChange, handleSearch }) => {
	const handleSubmit = e => {
		e.preventDefault()
		handleSearch()
	}
	return (
		<div className="project-search-wrap">
			<div className="page-title">
				<h2>UpWork Checker</h2>
			</div>
			<Paper component="form" className="flex-field" elevation={5} onSubmit={handleSubmit}>
				<Grid container spacing={16}>
					<Grid item xs>
						<TextField
							className="search-field"
							InputLabelProps={{ className: 'label' }}
							name="searchQuery"
							label="Url"
							value={searchValue}
							onChange={handleChange}
							placeholder="http://"
							variant="outlined"
						/>
					</Grid>
					<Grid item>
						<Button className="btn" variant="contained" color="primary" type="submit">
							{!loading && 'Search'}
							{loading && <CircularProgress size={22} thickness={5} color="inherit" />}
						</Button>
					</Grid>
				</Grid>
			</Paper>
			{data && !data.project && <p className="info">Nothing was found. Click "Add" for save new project.</p>}
		</div>
	)
}

export default ProjectSearch
