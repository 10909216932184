import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Popover, MenuItem, Avatar } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { logout } from '../../../actions/authActions'
import { ReactComponent as Logo } from '../../../static/img/logo.svg'
import { ReactComponent as MyProfileIcon } from '../../../static/img/my-profile-icon.svg'
import { ReactComponent as LogoutIcon } from '../../../static/img/logout-icon.svg'

class Header extends Component {
	state = {
		anchorEl: null,
	}

	toggleMenu = e => this.setState({ anchorEl: e.currentTarget })

	closeMenu = () => this.setState({ anchorEl: null })

	render() {
		const { user, logout } = this.props
		const { anchorEl } = this.state

		return (
			<header className="main-header">
				<div className="container">
					<div className="header-inner">
						<Logo />
						<Button onClick={this.toggleMenu} className="username">
							<Avatar className="avatar">
								{user.first_name[0]}
								{user.last_name[0]}
							</Avatar>
							<span>
								{user.first_name} {user.last_name}
							</span>
							<ExpandMore />
						</Button>
						<Popover
							anchorEl={anchorEl}
							open={!!anchorEl}
							onClose={this.closeMenu}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
							transformOrigin={{ vertical: 'top', horizontal: 'right' }}
							PaperProps={{ square: true }}
							classes={{ paper: 'header-menu' }}
						>
							<MenuItem className="item" component={Link} to="/profile">
								<MyProfileIcon className="icon" fill="#93a8bb" />
								My Profile
							</MenuItem>
							<MenuItem className="item" onClick={logout}>
								<LogoutIcon className="icon" fill="#93a8bb" />
								Logout
							</MenuItem>
						</Popover>
					</div>
				</div>
			</header>
		)
	}
}

export default connect(
	({ account: { user } }) => ({ user }),
	{ logout }
)(Header)
