import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import promise from 'redux-promise'

import { LOG_OUT } from '../actions/authActions'
import AccountReducer from './accountReducer'
import HistoryReducer from './historyReducer'
import SettingsReducer from './settingsReducer'
import ProjectReducer from './projectReducer'
import setAuthToken from '../common/utils/setAuthToken'

// sync with local storage
import { loadState, saveState } from '../storage'

// load data from local storage
const persistedState = loadState()

const appReducer = combineReducers({
	form: formReducer,
	account: AccountReducer,
	history: HistoryReducer,
	settings: SettingsReducer,
	project: ProjectReducer,
})

// clear all states and local storage on 'log out' action
const rootReducer = (state, action) => {
	if (action.type === LOG_OUT) {
		state = undefined
	}

	return appReducer(state, action)
}

// apply redux devtools extension
const composeEnhancers =
	typeof window === 'object' && process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose

/*const enhancer = composeEnhancers(
    applyMiddleware(promise)
);*/

// create redux store
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(promise)))

let currentAccount = persistedState ? persistedState.account : null

store.subscribe(() => {
	const state = store.getState()
	let nextAccount = state.account

	// check if our account was updated
	if (currentAccount !== nextAccount) {
		// store account to local storage
		saveState({
			account: nextAccount, // Always saved to local Storage current account state
		})

		// add auth token to each request (if exists)
		setAuthToken(state.account.token)

		currentAccount = nextAccount
	}
})

export default store
