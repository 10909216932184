import React from 'react'
import ContentRouter from '../../../routers/ContentRouter'

const Content = () => {
	return (
		<div className="container">
			<ContentRouter />
		</div>
	)
}

export default Content
