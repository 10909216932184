import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { login, resetPasswordRequest } from '../../actions/authActions'
import ResetPasswordDialog from '../content/Login/ResetPasswordDialog'
import LoginContent from '../content/Login/LoginContent'

const validate = values => {
	const errors = {}
	if (!values.email) {
		errors.email = 'Required'
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address'
	}
	if (!values.password) {
		errors.password = 'Required'
	}
	return errors
}

class Login extends Component {
	state = {
		resetPasswordDialogOpen: false,
	}

	openResetPasswordDialog = () => this.setState({ resetPasswordDialogOpen: true })

	closeResetPasswordDialog = () => this.setState({ resetPasswordDialogOpen: false })

	render() {
		const { resetPasswordDialogOpen } = this.state
		const { resetPasswordRequest } = this.props

		return (
			<>
				<ResetPasswordDialog
					onSubmit={resetPasswordRequest}
					open={resetPasswordDialogOpen}
					onClose={this.closeResetPasswordDialog}
				/>
				<LoginContent {...this.props} handleForgotPassword={this.openResetPasswordDialog} />
			</>
		)
	}
}

const mapDispatchToProps = dispatch => ({
	login: data => dispatch(login(data)),
	resetPasswordRequest: ({ email }) => dispatch(resetPasswordRequest(email)),
})

export default connect(
	null,
	mapDispatchToProps
)(
	reduxForm({
		form: 'loginForm',
		validate,
	})(Login)
)
