import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import UpworkChecker from '../components/containers/UpworkChecker'
import History from '../components/containers/History'
import ProfileSettings from '../components/containers/ProfileSettings'

const ContentRouter = () => {
	return (
		<Switch>
			<Route path="/upwork-checker/:id?" component={UpworkChecker} />
			<Route exact path="/history" component={History} />
			<Route exact path="/profile" component={ProfileSettings} />
			<Redirect from="/" to="/upwork-checker" />
		</Switch>
	)
}

export default withRouter(ContentRouter)
