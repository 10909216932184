import React from 'react'
import { Grid, Typography, Paper, Avatar } from '@material-ui/core'

const ProjectHistory = ({ project }) => {
	const { history } = project
	let color

	return (
		<Paper className="history-wrap">
			<Typography className="title" component="h3" variant="h6">
				History
			</Typography>
			{history.map((item, index) => {
				switch (item.status) {
					case 'Need review':
						color = 'blue'
						break
					case 'Declined':
						color = 'red'
						break
					default:
						color = 'green'
				}
				return (
					<Grid container spacing={16} key={index} className="history-item">
						<Grid item className="avatar-wrap">
							<Avatar className="avatar">
								{item.user.first_name[0]}
								{item.user.last_name[0]}
							</Avatar>
						</Grid>
						<Grid item xs container spacing={16} direction="column">
							<Grid item container>
								<Grid item className="name-wrap">
									<span className="name">{item.user.first_name}</span>
								</Grid>
								<Grid item xs>
									<span className="user-position">Designer / Project Manager</span>
								</Grid>
								<Grid item xs>
									<p className="created-at">{item.created_at}</p>
								</Grid>
							</Grid>
							<Grid item container>
								<Grid item>
									<p className="status-placeholder">Status:</p>
								</Grid>
								<Grid item>
									<span className={`status ${color}`}>{item.status}</span>
								</Grid>
							</Grid>
							<Grid item>{item.comment}</Grid>
						</Grid>
					</Grid>
				)
			})}
		</Paper>
	)
}

export default ProjectHistory
