import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core'

const Subheader = ({ location }) => {
	const links = [
		{
			path: '/upwork-checker',
			name: 'UpWork Cheker',
			show: true,
		},
		{
			path: '/history',
			name: 'History',
			show: true,
		},
	]
	return (
		<div className="subheader">
			<div className="container">
				{links.map(link => {
					if (link.show)
						return (
							<Button key={link.name} component={NavLink} to={link.path} className="subheader-button">
								{link.name}
							</Button>
						)
					return link
				})}
			</div>
		</div>
	)
}

export default Subheader
