import React from 'react'
import _ from 'lodash'
import {
	CircularProgress,
	Button,
	TextField,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Typography,
} from '@material-ui/core'

const ProjectForm = ({ data, searchValue, commentValue, statusValue, submiting, handleChange, addProject }) => {
	return (
		<div className="project-form-wrap">
			<Paper className="project-form">
				<Typography className="title" component="h3" variant="h6">
					Comment
				</Typography>
				<TextField
					className="comment-field"
					name="commentValue"
					label="Comment"
					multiline
					rows="6"
					value={commentValue}
					onChange={handleChange}
					variant="outlined"
					fullWidth
				/>
				<div className="flex-field">
					<FormControl variant="outlined">
						<InputLabel htmlFor="status">Status</InputLabel>
						<Select
							id="status"
							name="statusValue"
							className="status-select"
							value={statusValue}
							onChange={handleChange}
						>
							{data &&
								_.map(data.availableStatuses, (status, value) => (
									<MenuItem key={value} value={value}>
										{status}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					<Button
						className="btn"
						variant="contained"
						color="primary"
						onClick={addProject}
						disabled={(!commentValue && !statusValue) || (data.project && data.project.url !== searchValue)}
					>
						{!submiting ? (
							data && data.project ? (
								'Update'
							) : (
								'Add'
							)
						) : (
							<CircularProgress size={22} thickness={5} color="inherit" />
						)}
					</Button>
				</div>
			</Paper>
		</div>
	)
}

export default ProjectForm
